import React from 'react';
import { navigate } from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <form
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={this.handleSubmit}
        action="/contact/success/"
        className="w-full max-w-sm mx-auto"
        method="post"
        name="contact"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label htmlFor="bot-field">
            Don’t fill this out:{' '}
            <input
              id="bot-field"
              name="bot-field"
              onChange={this.handleChange}
            />
          </label>
        </div>

        {/* Name */}
        <div className="mb-6">
          <label className="flex flex-col" htmlFor="name">
            <span className="mb-2 text-sm font-bold">Name:</span>
            <input
              className="w-full px-3 py-2 leading-tight bg-white border rounded appearance-none hover:border-grey hover:shadow text-grey-darker focus:outline-none focus:shadow-outline"
              id="name"
              name="name"
              onChange={this.handleChange}
              required
              type="text"
            />
          </label>
        </div>

        {/* Email address */}
        <div className="mb-6">
          <label className="flex flex-col" htmlFor="email">
            <span className="mb-2 text-sm font-bold">Email address:</span>
            <input
              className="w-full px-3 py-2 leading-tight bg-white border rounded appearance-none hover:border-grey hover:shadow text-grey-darker focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
              onChange={this.handleChange}
              required
              type="email"
            />
          </label>
        </div>

        {/* Phone number */}
        <div className="mb-6">
          <label className="flex flex-col" htmlFor="phone">
            <span className="mb-2 text-sm font-bold">Phone number:</span>
            <input
              className="w-full px-3 py-2 leading-tight bg-white border rounded appearance-none hover:border-grey hover:shadow text-grey-darker focus:outline-none focus:shadow-outline"
              id="phone"
              name="phone"
              onChange={this.handleChange}
              type="text"
            />
          </label>
        </div>

        {/* Enquiry type */}
        <div className="mb-6">
          {/* eslint-disable-next-line */}
          <label
            className="flex flex-col mb-2 text-sm font-bold"
            htmlFor="enquiry"
          >
            <span className="">Enquiry type: </span>
            <div className="relative block w-full">
              <select
                className="w-full px-4 py-2 pr-8 leading-tight bg-white border rounded appearance-none border-grey-light hover:border-grey hover:shadow text-grey-darker focus:outline-none focus:shadow-outline"
                id="enquiry"
                name="enquiry"
                onChange={this.handleChange}
                required
              >
                <option disabled selected>
                  What would you like to know?{' '}
                </option>
                <option value="Purchase a company">Purchase a company </option>
                <option value="Purchase SMSF">Purchase SMSF </option>
                <option value="Purchase Life Insurance">
                  Purchase Life Insurance{' '}
                </option>
                <option value="Purchase Income Protection">
                  Purchase Income Protection{' '}
                </option>
                <option value="Other">Other </option>
              </select>
              <div className="absolute flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker">
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </label>
        </div>

        {/* Message */}
        <div className="mb-6">
          <label className="flex flex-col" htmlFor="message">
            <span className="mb-2 text-sm font-bold">Message:</span>
            <textarea
              className="w-full px-3 py-2 leading-tight bg-white border rounded appearance-none hover:border-grey hover:shadow text-grey-darker focus:outline-none focus:shadow-outline"
              id="message"
              name="message"
              onChange={this.handleChange}
              required
              rows={5}
            />
          </label>
        </div>

        <div className="flex items-center justify-end mb-6">
          {/* Clear */}
          <input
            className="px-3 py-2 mr-3 font-bold leading-tight text-red-600 rounded appearance-none bg-red-050 hover:shadow"
            onChange={this.handleChange}
            type="reset"
            value="Clear form"
          />

          {/* Submit */}
          <button
            className="px-3 py-2 font-bold leading-tight text-green-600 rounded appearance-none bg-green-050 hover:shadow"
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
    );
  }
}
